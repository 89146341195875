<template>
  <div>
    <v-btn
      color="primary"
      dark
      class="mb-4 print-btn"
      @click.prevent="printPage()"
      >Распечатать</v-btn
    >
    <div style="flex-wrap: wrap;justify-content: space-between;">
      <template v-for="order in ordersList">
        <template v-for="(elem, index) in order.bouquets">
          <template v-for="n in elem.count">
            <table
              style="width: 100%; padding: 10px; border: 1px solid black; margin-bottom: 40px;"
              :key="`${index}-${n}`"
              class="print-blank"
            >
              <tbody>
                <tr>
                  <td style="width: 73px;">
                    <b>Дата</b>
                  </td>
                  <td
                    colspan="3"
                    style="width: 100%; text-align: center; font-size: 30px; font-weight: 900"
                  >
                    {{ order.date }}
                    <p></p>
                    {{ order.delivery_time }}
                  </td>
                  <td style="width: 204px; text-align: right; font-size: 70px;">
                    <b>{{ (order.delivery_type_id === 2) ? 'Доставка' : 'Самовывоз' }}</b>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" style="width: 100%; text-align: center;">
                    <span><b>№ Заказа: </b></span>
                    <u style="font-size: 90px;">{{ order.id }}</u>
                  </td>
                </tr>
                <tr>
                  <td colspan="1" style="width: 10%">
                    <strong style="font-size: 20px;">Заказчик: </strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="5" style="width: 182px; text-align: left;">
                    <u style="font-size: 70px; font-weight: 900;">{{ order.client.name }}</u>
                  </td>
                </tr>
                <tr>
                  <td colspan="5" style="width: 73px; padding-top: 10px;">
                    <p style="text-align: justify;">
                      <strong>Комментарий:</strong>
                      <span contenteditable="true" style="margin-left: 5px;">
                        {{ order.comment }}
                      </span>
                    </p>
                  </td>
                </tr>
                <!-- <tr>
                  <td colspan="5" style="width: 73px; padding-top: 10px;">
                    <p style="text-align: justify;">
                      <strong>Оплачен:</strong>
                      <span style="margin-left: 5px;">
                        {{ (order.already_paid) ? 'Да' : 'Нет' }}
                      </span>
                    </p>
                  </td>
                </tr> -->
                <tr>
                  <td colspan="5" style="width: 73px; padding-top: 10px;">
                    <p style="text-align: justify;">
                      <strong>Фото по готовности:</strong>
                      <span style="margin-left: 5px;">
                        {{ (order.is_photo) ? 'Да' : 'Нет' }}
                      </span>
                    </p>
                  </td>
                </tr>
                <!-- <tr>
                  <td colspan="5" style="width: 73px; padding-top: 10px;">
                    <p style="text-align: justify;">
                      <strong>Звонить предварительно:</strong>
                      <span style="margin-left: 5px;">
                        {{ (order.is_phone_call) ? 'Да' : 'Нет' }}
                      </span>
                    </p>
                  </td>
                </tr> -->
                <tr>
                  <td colspan="5" style="width: 73px;">
                    <strong>Букет:</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="5" style="width: 73px;">
                    {{ elem.name }}
                    <!-- (кол-во: {{ elem.count }}) -->
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </template>
      </template>
    </div>
    <v-btn
      color="primary"
      dark
      class="mt-4 print-btn"
      @click.prevent="printPage()"
      >Распечатать</v-btn
    >
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      ids: [],
      ordersList: []
    };
  },
  methods: {
    getItems() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-print`;

      const props = {
        order_ids: this.ids,
      };

      axios
        .post(url, props)
        .then((response) => {
          this.ordersList = response.data.map((item) => {
            const order = item;

            order.date = this.getDateFormat(order.date);
            return order;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    printPage() {
      window.print();
    },
    getDateFormat(date) {
      const dateStr = date.split('T')[0];
      const dateArray = dateStr.split('-');

      return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`
    },
  },
  mounted() {
    const ids = this.$route.params.ids.split(',');
    this.ids = ids.map((val) => +val);
    this.getItems();
  }
};
</script>

<style lang="scss">
@media print {
  .print-blank:nth-child(even) {
    break-after: always;
    page-break-after: always;
  }
}
</style>
